<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-sheet class="pa-8 mb-4">
      <h1 class="text-center mb-5">{{ $t("godparent-system") }}</h1>
      <p class="text-center">{{ $t("godparent-text-1") }}</p>
      <p class="text-center">
        {{ $t("godparent-text-2") }}
        <a :href="'mailto:' + helpAddress">{{ helpAddress }}</a>
        {{ $t("godparent-text-3") }}
      </p>
    </v-sheet>
    <v-container v-if="godparents == null" class="text-center">
      <v-progress-circular indeterminate size="64" class="mt-5" />
    </v-container>
    <v-container v-else-if="error == true">
      <v-alert type="error" max-width="300px" class="mx-auto text-center">{{
        godparents
      }}</v-alert>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col
          v-for="(godparent, index) in godparents"
          :key="index"
          xl="4"
          lg="6"
          cols="12"
        >
          <v-card elevation="2" shaped>
            <v-img
              :src="'https://api.bauchgefuehle-giessen.de/' + godparent.image"
              height="300px"
            />
            <v-card-title>{{ godparent.name }}</v-card-title>
            <v-card-text v-html="godparent.text"></v-card-text>
            <v-card-actions>
              <v-btn
                v-if="$vuetify.breakpoint.xsOnly"
                :href="'mailto:' + godparent.mail"
                text
                color="accent"
                class="mx-3 mb-3"
                small
                ><v-icon>mdi-email</v-icon>{{ godparent.mail }}</v-btn
              >
              <v-btn
                v-else
                :href="'mailto:' + godparent.mail"
                text
                color="accent"
                class="mx-3 mb-3"
                ><v-icon class="mr-2">mdi-email</v-icon
                >{{ godparent.mail }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import axios from "axios";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "godparent",
  components: {
    Breadcrumbs
  },
  data: () => ({
    helpAddress: "hilfe@bauchgefuehle-giessen.de",
    godparents: null,
    error: false
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("group"),
          disabled: false,
          href: "/group"
        },
        {
          text: this.$t("godparent-system"),
          disabled: true
        }
      ];
    }
  },
  mounted() {
    axios
      .get("https://api.bauchgefuehle-giessen.de/api/godparents")
      .then(response => this.parseGodparents(response))
      .catch(error => this.parseGodparents(error));
  },
  methods: {
    parseGodparents(response) {
      if (response.status != 200 || !Array.isArray(response.data.data)) {
        this.error = true;
        this.godparents = this.$t("communication-error");
      } else {
        this.godparents = response.data.data;
      }
    }
  }
};
</script>
<style scoped>
a {
  color: #ffc107 !important;
  font-weight: bold;
  text-decoration: none;
}
</style>
