import { render, staticRenderFns } from "./Godparent.vue?vue&type=template&id=07e7f41a&scoped=true&"
import script from "./Godparent.vue?vue&type=script&lang=js&"
export * from "./Godparent.vue?vue&type=script&lang=js&"
import style0 from "./Godparent.vue?vue&type=style&index=0&id=07e7f41a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07e7f41a",
  null
  
)

export default component.exports